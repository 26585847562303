var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "info-dk",
    attrs: {
      "href": ((_vm.$config.darkstoreUrl) + "?utm_source=main_infoblock_05_top&utm_medium=referral&utm_campaign=05")
    }
  }, [_vm._m(0), _vm._v(" "), _c('img', {
    staticClass: "info-dk__yellow-star",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/time.svg"),
      "alt": "доставка за 30 минут"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "info-dk__cheese-mobile",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/cheese-mobile.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/cheese-mobile.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/cheese-mobile@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/cheese-mobile@3x.png") + " 3x",
      "alt": "Сыр"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "info-dk__grape-mobile",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/grape-mobile.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/grape-mobile.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/grape-mobile@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/grape-mobile@3x.png") + " 3x",
      "alt": "Виноград"
    }
  }), _vm._v(" "), _vm._m(1), _vm._v(" "), _c('div', {
    staticClass: "info-dk__button"
  }, [_vm._v("\n    Перейти\n  ")])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-dk__inner"
  }, [_c('div', {
    staticClass: "info-dk__inner-title"
  }, [_c('img', {
    staticClass: "info-dk__inner-title-img",
    attrs: {
      "src": require("assets/images/infoblock-darkstore/darkstore-logo.svg"),
      "alt": "даркстор"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "info-dk__inner-title-text"
  }, [_vm._v("даркстор")])]), _vm._v(" "), _c('div', {
    staticClass: "info-dk__inner-subtitle"
  }, [_vm._v("\n      Быстрая\n      "), _c('br'), _vm._v("\n      доставка продуктов\n    ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "info-dk__products"
  }, [_c('img', {
    staticClass: "info-dk__products-grape",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/grape.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/grape.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/grape@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/grape@3x.png") + " 3x",
      "alt": "Виноград"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "info-dk__products-cheese",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/cheese.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/cheese.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/cheese@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/cheese@3x.png") + " 3x",
      "alt": "Сыр"
    }
  }), _vm._v(" "), _c('img', {
    staticClass: "info-dk__products-milk",
    attrs: {
      "src": require("assets/images/infoblock-darkstore-makhachkala/milk.png"),
      "srcset": require("assets/images/infoblock-darkstore-makhachkala/milk.png") + " 1x, " + require("assets/images/infoblock-darkstore-makhachkala/milk@2x.png") + " 2x, " + require("assets/images/infoblock-darkstore-makhachkala/milk@3x.png") + " 3x",
      "alt": "Молоко"
    }
  })]);
}]

export { render, staticRenderFns }